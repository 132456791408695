$font_family_sans: 'sofia-pro-soft', serif;
$font_size_large: 24px;
$font_size_regular: 18px;
$font_size_small: 14px;

// colors
$black: #30323e;
$dark_grey: #676A7F;
$light_grey: #F6F8F9;
$background_color: $light_grey;
$blue: #0026DD;
$primary_color: $blue;
$middle_grey: #7d7f8c;
$red: rgba(218,12,60,1);
$yellow: rgba(245, 194, 15, 1);
$green: #00CD84;

// breakpoints
$break-xs: 575px;
$break-sm: 767px;
$break-md: 991px;
$break-lg: 1199px;

// spacings
$spacing_tiny: 10px;
$spacing_small: 20px;
$spacing_medium: 40px;
$spacing_large: 80px;

// misc
$default_border_radius: 4px;
$default_letter_spacing: .01em;
$default_box_shadow: 0 0 15px -1px rgba($blue, .1);

$default_transition_ease: ease-in-out;
$default_transition: .1s $default_transition_ease;
