.table.users{
  .td.actions{
    margin-left: auto;
  }
  .tr--header{
    @media screen and (max-width: 800px){
      display: none;
    }
  }
  .tr{
    @media screen and (max-width: 800px){
      flex-wrap: wrap;
    }
  }

  .td{
    &:nth-child(1){
      flex-basis: 200px; font-weight: 600;

      @media screen and (max-width: 800px){
        flex-basis: 100%;
      }
    }
    &:nth-child(2){ flex-basis: 140px}
    &:nth-child(3){ flex-basis: 300px}
    &:nth-child(4){ flex-basis: 120px}
  }
}
