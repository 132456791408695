.header{
  padding: 30px 0;
  border-bottom: 1px solid $light_grey;
  font-size: 16px;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50;
  transition: $default_transition;

  &.scrolled{
    padding: 10px 0;
    box-shadow: $default_box_shadow;
    border-bottom-color: transparent;
  }

  @include breakSm{
    padding: 20px 0;
  };

  &__menu-toggle{
    width: 44px;
    height: 34px;
    margin-left: -10px;
    border-radius: $default_border_radius;
    flex-direction: column;
    align-items: stretch;
    padding: 6px 10px;
    margin-bottom: 5px;
    justify-content: space-around;
    display: none;

    @include breakSm{
      display: flex;
      // NOTE: hidden until menu has content implemented
      // display: none;
    };

    > span{
      height: 2px;
      border-radius: 4px;
      width: 100%;
      background-color: $black;
    }
  }
  &__logo{
    height: 20px;
  }
  &__menu{
    padding: 0;
    margin: 0;
    margin-left: 40px;
    margin-right: auto;
    list-style-type: none;

    li{
      display: inline-block;

      &:not(:last-child){
        margin-right: 30px;
      }

      a{
        color: rgba($black, .6);
        transition: color .1s ease-in-out;

        &:hover{
          color: rgba($black, .8);
        }
        &.active{
          color: $black;
        }
      }
    }
  }
  &__actions{
    display: flex;
    align-items: center;

    @include breakSm{
      margin-left: auto;
    };

    .support{
      margin-right: 20px;
      color: $primary_color;

      @include breakMd{
        display: none;
      };
    }
    .logout{
      display: flex;
      align-items: center;
      transition: all $default_transition;
      cursor: pointer;

      svg{
        margin-right: 15px;
      }
      .label{
        @include breakMd{
          display: none;
        }
      }

      &:hover{
        opacity: .7;
      }
    }
  }
}
