.table.properties{
  .td.actions{
    margin-left: auto;
  }
  .td{
    &:nth-child(1){
      flex-basis: 180px; font-weight: 600;

      @media screen and (max-width: 800px){
        flex-basis: 100%;
      }
    }
    &:nth-child(2){ flex-basis: 220px}
    &:nth-child(3){ flex-basis: 100px}
    &:nth-child(4){ flex-basis: 160px}
    &:nth-child(5){ flex-basis: 180px}
  }
}
