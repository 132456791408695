.context-menu{
  position: relative;

  &__toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    > span{
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: $middle_grey;
      margin: 2px;
      border-radius: 100%;
      transition: $default_transition;
    }

    &:hover > span{
      background-color: $blue;
    }
  }
  &__menu{
    position: absolute;
    background-color: white;
    box-shadow: 0 0 15px -1px rgba($blue, .2);;
    border-radius: $default_border_radius;
    z-index: 10;
    right: 0;

    opacity: 0;
    pointer-events: none;
    transform: translateY(-2px) scale(.96);
    transition: $default_transition;

    &--visible{
      opacity: 1;
      pointer-events: all;
      transform: none;
    }

    ul{
      padding: 0;
      margin: 0;
      list-style-type: none;

      li{
        display: block;
        white-space: nowrap;
        padding: 5px 15px;
        font-size: 16px;
        color: $dark_grey;
        transition: $default_transition;

        &.disabled{
          cursor: default;
          opacity: .5;
        }
        &.red{
          color: $red;

          &:not(.disabled):hover{
            background-color: rgba($red, .03);
            color: $red;
          }
        }
        &:not(.disabled):hover{
          background-color: rgba($light_grey, .5);
          color: $black;
        }
        &:not(:last-child){
          border-bottom: 1px solid $light_grey;
        }
      }
    }
  }
}
