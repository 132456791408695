.properties{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-left: -10px;
  margin-right: -10px;

  .property{
    width: calc(20% - 20px);
    margin: 0 10px;
    margin-bottom: 20px;

    @include breakMd{
      width: calc(50% - 20px);
    }
    &__link{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      padding: 20px;
      min-height: 300px;
      background-color: white;
      box-shadow: $default_box_shadow;
      border-radius: $default_border_radius;
      transition: all $default_transition;

      @include breakSm{
        min-height: 240px;
      }

      &:hover{
        transform: translateY(-3px);

        .property__delete{
          opacity: 1;
        }
      }
    }
    &__delete{
      position: absolute;
      top: 20px;
      right: 20px;
      opacity: 0;
      color: $red;

      &:hover{
        opacity: .7 !important;
      }
    }

    &__image{
      width: 100px;
      margin-bottom: -20px;
      margin-left: -5px;
    }

    &__title{
      font-size: 16px;
      color: $black;
    }
    &__address{
      font-size: 14px;
      color: $middle_grey;
      line-height: 1.5em;
    }

    &--placeholder .property__link{
      border: 2px dashed darken($light_grey, 10%);
      box-shadow: none;

      .property__title{
        color: $middle_grey;
        margin-top: auto;

        &:before{
          content: 'add';
          @extend .material-icons;
          font-size: 16px;
          display: inline-block;
          vertical-align: middle;
          margin-right: .3em;
        }
      }
    }
  }
}
