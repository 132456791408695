.table{
  .tr{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    border-radius: $default_border_radius;
    padding: 10px 20px;
    color: $black;

    @media screen and (max-width: 800px){
      flex-wrap: wrap;
    }

    &:not(.tr--header){
      background-color: white;
      box-shadow: $default_box_shadow;

      & + .tr{
        margin-top: 15px;
      }
    }
    &--header .td{
      color: $middle_grey;
      font-size: $font_size_small;
    }
  }
  a.tr{
    transition: all .1s ease-in-out;

    .td{
      transition: all .1s ease-in-out;
    }

    &:hover{
      box-shadow: 0 3px 15px -1px rgba($dark_grey, .3);

      // NOTE: transform not working with position absolute of context menu
      // transform: translateY(-1px);

      .td{
        color: $primary_color;
      }
    }
  }
  .tr--header{
    @media screen and (max-width: 800px){
      display: none;
    }
  }
  .td{
    padding: 5px 0;
    padding-right: 1em;
  }
}
